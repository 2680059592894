import { render, staticRenderFns } from "./ViewSpacesPage.vue?vue&type=template&id=3453d601&"
import script from "./ViewSpacesPage.vue?vue&type=script&lang=ts&"
export * from "./ViewSpacesPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
